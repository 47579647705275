<template>
    <div id="products-list">


        <b-modal centered size="sm" id="modal-prevent-closing" ref="add-to-cart-modal" :title="item.description"
            hide-footer>
            <b-form-input placeholder="La quantité demandée" ref="qte-input" v-model="requestedQte" type="number"
                @keyup.enter="handleOk"></b-form-input>
            <div class="d-block text-center">
                <b-button variant="relief-success" class="mt-1" @click="handleOk">Valider</b-button>
            </div>
        </b-modal>


        <b-modal hide-footer ref="substitution-items-model" title="Articles de substitution" scrollable>

            <ag-grid :showHeader="false" :agGrid="agGridSubstitution" ref="agGridSubstitution"
                @cellDoubleClicked="cellSubstitutionDoubleClicked"
                @onFirstDataRendered="onSubstitutionFirstDataRendered" />

        </b-modal>


        <b-overlay :show="showLoading" spinner-variant="primary">

            <b-alert variant="danger" :show="itemNotFound" class="m-1">
                <div class="alert-body">
                    <feather-icon icon="XSquareIcon" class="mr-50" />
                    Nous regrettons de vous informer que cet article n'est pas vendable auprès du grossiste sélectionné
                    '{{ companyName }}'. Veuillez choisir un autre grossiste.
                </div>
            </b-alert>


            <b-alert variant="warning" :show="outOfStock" class="m-1">
                <div class="alert-body">
                    <feather-icon icon="AlertOctagonIcon" class="mr-50" />
                    Désolé, cet article est actuellement en rupture de stock auprès du grossiste sélectionné
                    '{{ companyName }}'. Veuillez choisir un autre grossiste.
                </div>
            </b-alert>


            <b-card :key="productKey">


                <div class="mt-md-0 mt-2 mb-1">
                    <b-row>
                        <b-col cols="12" sm="6">
                            <h3 class="text-primary invoice-logo">
                                {{ item.description }}
                            </h3>
                        </b-col>
                        <b-col cols="12" sm="6" class="text-right d-none d-md-block" v-if="isPharmacy">
                            <b-button size="sm" variant="outline-warning " pill class="mr-50"
                                @click="fav ? $store.dispatch('app-favorites/DELETE_FAVORITE', item) : $store.dispatch('app-favorites/ADD_FAVORITE', item); fav = !fav;">
                                <feather-icon icon="HeartIcon" :class="{ 'fill-current': fav }" class="mr-50" />
                                <span> {{ fav ? 'Retirer des Favoris' : 'Ajouter aux Favoris' }}</span>
                            </b-button>
                            <b-button size="sm" variant="success" pill :disabled="item.inventory <= 0"
                                @click="addToCart">
                                <feather-icon icon="ShoppingCartIcon" class="mr-50" />
                                <span class="align-middle">Ajouter à la Commande Courante</span>
                            </b-button>
                        </b-col>
                        <b-col cols="12" sm="6" class="text-center d-md-none" v-if="isPharmacy">
                            <b-button size="sm" variant="success" pill :disabled="item.inventory <= 0"
                                @click="addToCart">
                                <feather-icon icon="ShoppingCartIcon" class="mr-50" />
                                <span class="align-middle">Ajouter à la Commande Courante</span>
                            </b-button>
                        </b-col>
                    </b-row>
                </div>

                <hr>
                <br>

                <b-row>
                    <b-col cols="6" sm="4">

                        <h5 class="mt-md-0 mt-2 mb-1">
                            Dosage :
                            <code style="color : #28c76f ">{{ item.dosage }}</code>
                        </h5>
                        <h5 class="mt-md-0 mt-2 mb-1">
                            Présentation :
                            <code style="color : #28c76f ">{{ item.presentation }}</code>
                        </h5>
                        <h5 class="mt-md-0 mt-2 mb-1">
                            Conditionnement primaire :
                            <code style="color : #28c76f ">{{ item.primaryPackaging }}</code>
                        </h5>
                        <h5 class="mt-md-0 mt-2 mb-1">
                            Spécification :
                            <code style="color : #28c76f ">{{ item.specificationPrimaryPackaging }}</code>
                        </h5>
                        <h5 class="mt-md-0 mt-2 mb-1">
                            Tableau :
                            <code style="color : #28c76f ">{{ item._table }}</code>
                        </h5>
                        <h5 class="mt-md-0 mt-2 mb-1">
                            Classe Thérapeutique :
                            <code style="color : #28c76f ">{{ item._class }}</code>
                        </h5>
                        <h5 class="mt-md-0 mt-2 mb-1">
                            Sous Classe :
                            <code style="color : #28c76f ">{{ item.subClass }}</code>
                        </h5>
                        <h5 class="mt-md-0 mt-2 mb-1">
                            Classement VEIC :
                            <code style="color : #28c76f ">{{ item.veic }}</code>
                        </h5>
                        <h5 class="mt-md-0 mt-2 mb-1">
                            Forme :
                            <code style="color : #28c76f ">{{ item.form }}</code>
                        </h5>
                    </b-col>
                    <b-col cols="6" sm="4">
                        <h5 class="mt-md-0 mt-2 mb-1">
                            DCI :
                            <code style="color : #28c76f ">{{ item.dciDescription }}</code>
                        </h5>
                        <h5 class="mt-md-0 mt-2 mb-1">
                            Laboratoire :
                            <code style="color : #28c76f ">{{ item.vendorDescription }}</code>
                        </h5>
                        <h5 class="mt-md-0 mt-2 mb-1">
                            Accord préalable:
                            <code>{{ item.apcnam }}</code>
                        </h5>
                        <h5 class="mt-md-0 mt-2 mb-1">
                            P.C.T :
                            <code :class="item.priceHT == undefined ? 'shimmer-line' : ''"
                                style="color : #7367f0;animation: shimmer 5s linear infinite;"
                                :style="item.priceHT == undefined ? 'padding-right: 240px;' : ''">{{ item.pct }}</code>
                        </h5>
                        <h5 class="mt-md-0 mt-2 mb-1">
                            Prix H.T :
                            <code :class="item.priceHT == undefined ? 'shimmer-line' : ''"
                                style="color : #7367f0;animation: shimmer 5s linear infinite;"
                                :style="item.priceHT == undefined ? 'padding-right: 240px;' : ''">{{ item.priceHT }}</code>
                        </h5>
                        <h5 class="mt-md-0 mt-2 mb-1">
                            Prix T.T.C :
                            <code :class="item.priceTTC == undefined ? 'shimmer-line' : ''"
                                style="color : #7367f0;animation: shimmer 5s linear infinite;"
                                :style="item.priceTTC == undefined ? 'padding-right: 240px;' : ''">{{ item.priceTTC }}</code>
                        </h5>
                        <h5 class="mt-md-0 mt-2 mb-1">
                            Prix Public :
                            <code :class="item.pricePublic == undefined ? 'shimmer-line' : ''"
                                style="color : #7367f0;animation: shimmer 5s linear infinite;"
                                :style="item.pricePublic == undefined ? 'padding-right: 240px;' : ''">{{ item.pricePublic }}</code>
                        </h5>
                        <h5 class="mt-md-0 mt-2 mb-1">
                            DLC :
                            <code :class="item.dlc == undefined ? 'shimmer-line' : ''"
                                style="color : #7367f0;animation: shimmer 5s linear infinite;"
                                :style="item.dlc == undefined ? 'padding-right: 240px;' : ''">{{ item.dlc }}</code>
                        </h5>
                        <h5 class="mt-md-0 mt-2 mb-1">
                            Cartonnage :
                            <code :class="item.packaging == undefined ? 'shimmer-line' : ''"
                                style="color : #7367f0;animation: shimmer 5s linear infinite;"
                                :style="item.packaging == undefined ? 'padding-right: 240px;' : ''">{{ item.packaging }}</code>
                        </h5>

                    </b-col>
                    <b-col cols="12" sm="4">

                        <h5 class="mt-md-0 mt-2 mb-1">
                            Indications :
                        </h5>

                        <div v-if="item.indications == undefined" class="shimmer-line"
                            style="animation: shimmer 2.5s linear infinite;height: 90%;"></div>
                        <quill-editor v-else-if="item.indications != ''" disabled :options='editorOption'
                            v-model='item.indications'></quill-editor>

                    </b-col>
                </b-row>

                <!-- <hr>
                <br>
                <b-row v-if="isPharmacy">

                    <b-col class="text-left d-none d-md-block" cols="12" sm="8">
                        <b-button variant="outline-secondary" size="sm" @click="showSubstitutionItems">
                            <feather-icon icon="CopyIcon" class="mr-50" />
                            <span>Articles de Substitution</span>
                        </b-button>
                    </b-col>


                    <b-col cols="12" class="d-md-none">
                    </b-col>
                    <b-col cols="12" class="d-md-none">
                        <b-button variant="outline-secondary" size="sm" @click="showSubstitutionItems" block class="mt-50">
                            <feather-icon icon="CopyIcon" class="mr-50" block />
                            <span>Articles de Substitution</span>
                        </b-button>
                    </b-col>


                    <b-col class="text-right d-none d-md-block" cols="12" sm="4">
                    </b-col>

                </b-row> -->
            </b-card>

        </b-overlay>

    </div>
</template>

<script>

import 'quill/dist/quill.core.css';
import 'quill/dist/quill.snow.css';
import 'quill/dist/quill.bubble.css';

import {
    quillEditor
} from 'vue-quill-editor';
import AgGrid from "@/views/components/ag-grid/AgGrid.vue"
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import ButtonCellRenderer from "@/views/components/ag-grid/ButtonCellRenderer.vue"
import { isPharmacy } from "@/auth/utils";

export default {
    props: {
        product: {
            type: Object,
            required: true
        },
    },
    components: {
        quillEditor,
        AgGrid
    },
    data() {
        return {
            fav: false,
            isPharmacy: isPharmacy(),
            requestedQte: '',
            item: {},
            editorOption: {
                theme: 'bubble',
                readOnly: true
            },
            itemNotFound: false,
            outOfStock: false,
            companyName: "",
            productKey: 0,
            agGridSubstitution: {
                columnDefs: [
                    {
                        headerName: "Article",
                        field: "Description",
                    },
                ],
                rows: []
            },
            showLoading: false,
        }
    },
    async created() {
        let fav = await this.$store.dispatch('app-favorites/FIND_FAVORITE', this.product.code);
        this.fav = fav != undefined;
        if (!window.location.href.includes("list")) {
            this.$options.components.buttonCellRenderer = ButtonCellRenderer;
            this.agGridSubstitution.columnDefs = [
                {
                    headerName: "Article",
                    field: "Description",
                },
                {
                    sortable: false,
                    resizable: false,
                    filter: false,
                    cellClass: 'centered-customeCellRender',
                    cellRendererFramework: 'buttonCellRenderer',
                    cellRendererParams: { componentName: 'ProductList', onButtonClick: this.cellSubstitutionDoubleClicked, onAddClick: this.productClick },
                    headerName: '',
                    width: 70,
                },
            ];
        }
        this.item = this.product;
        this.item.inventory = 0;
        let response = await this.$http.get("_item/itemCompanyInfo/" + this.item.code);
        // console.log(response);
        if (response.data.status == 200) {
            this.companyName = response.data.result.company;
            this.item.priceHT = response.data.result.priceHT + " TND";
            this.item.priceTTC = response.data.result.priceTTC + " TND";
            this.item.pricePublic = response.data.result.pricePublic + " TND";
            this.item.dlc = response.data.result.dlc == "0001-01-01" ? "___" : response.data.result.dlc;
            this.item.packaging = response.data.result.packaging;
            this.item.indications = response.data.result.indications == null ? '' : response.data.result.indications;
            this.item.inventory = response.data.result.inventory;
            this.item.pct = response.data.result.pct;
            this.item.apcnam = response.data.result.apcnam ? 'Oui' : 'Non';
            this.outOfStock = response.data.result.inventory <= 0;
            this.itemStatus = "";
            this.productKey += 1;
        } else {
            this.companyName = response.data.result.company;
            this.itemNotFound = true;
            this.item.priceHT = "___";
            this.item.priceTTC = "___";
            this.item.pricePublic = "___";
            this.item.dlc = "___";
            this.item.packaging = "___";
            this.item.pct = response.data.result.pct;
            this.item.apcnam = response.data.result.apcnam ? 'Oui' : 'Non';
            this.item.indications = response.data.result.indications == null ? '' : response.data.result.indications;
            this.productKey += 1;
        }
    },
    methods: {
        handleOk() {
            this.$refs['add-to-cart-modal'].hide();
            this.showLoading = true;
            this.item["Qte"] = this.requestedQte;
            this.$store.dispatch('app-items/GET_INVENTORY', this.item).then((response) => {
                this.showLoading = false;
                this.$toast.clear();
                this.$toast({
                    component: ToastificationContent,
                    props: response,
                },
                    {
                        timeout: 2000
                    });
                if (!["warning", "danger"].includes(response.variant))
                    this.$emit('itemAddedToCart');
            }).catch((error) => {
                this.$toast.clear();
                this.showLoading = false;
                console.log(error);
            });
        },
        addToCart() {
            this.requestedQte = "";
            this.$refs['add-to-cart-modal'].show();
            setTimeout(() => {
                this.$refs["qte-input"].focus();
            }, 400);
        },
        async cellSubstitutionDoubleClicked(data) {
            const sub = data.Substitute_No == undefined ? data.data.Substitute_No : data.Substitute_No;
            let resp = await this.$http.get("_item/getItemCodeByItemNo/" + sub);
            if (resp.data != "") {
                let itemToAdd = this.$store.getters["app-items/geItemByCode"](resp.data);
                this.$emit('sibstitutionItemClickDetail', itemToAdd);
            } else {
                this.$swal({
                    title: "Article Actuellement Indisponible",
                    text: "Désolé, cet article n'a pas encore été créé chez Pharmaconnect.",
                    icon: 'warning',
                    customClass: {
                        confirmButton: 'btn btn-primary',
                    },
                    buttonsStyling: false,
                })
            }
            this.showLoadingModal = false;
        },
        async productClick(data) {
            let resp = await this.$http.get("_item/getItemCodeByItemNo/" + data.Substitute_No);
            if (resp.data != "") {
                let itemToAdd = this.$store.getters["app-items/geItemByCode"](resp.data);
                this.$emit('sibstitutionItemClick', itemToAdd);
            } else {
                this.$swal({
                    title: "Article Actuellement Indisponible",
                    text: "Désolé, cet article n'a pas encore été créé chez Pharmaconnect.",
                    icon: 'warning',
                    customClass: {
                        confirmButton: 'btn btn-primary',
                    },
                    buttonsStyling: false,
                })
            }
            this.showLoadingModal = false;
        },
        async showSubstitutionItems() {
            this.showLoading = true;
            let resp = await this.$http.get("_item/getItemNoByItemCode/" + this.item.code).catch(() => this.showLoading = false);
            // console.log(resp);
            if (resp.data != "") {
                let respItem = await this.$http.get('substitution_item/findWhere', { params: { No: "'" + resp.data + "'" } }).catch(() => this.showLoading = false);
                // console.log(respItem);
                setTimeout(() => {
                    this.agGridSubstitution.rows = respItem.data;
                }, 300);
                this.showLoading = false;
                this.$refs['substitution-items-model'].show();
            } else {
                this.$swal({
                    title: "Article Indisponible",
                    text: "Malheureusement, le grossiste sélectionné ne propose pas l'article " + this.item.description,
                    icon: 'warning',
                    customClass: {
                        confirmButton: 'btn btn-primary',
                    },
                    buttonsStyling: false,
                });
                this.showLoading = false;
            }
        },
        onSubstitutionFirstDataRendered() {
            this.$refs.agGridSubstitution.gridOptions.api.sizeColumnsToFit();
        },
    }
}
</script>


<style scoped lang="scss">
@media (max-width: 768px) {
    .card {
        background-color: initial;
        box-shadow: initial;
    }

    .card-body {
        padding: 0px;
    }
}
</style>



<style lang="scss">
.shimmer-line {
    height: 200px;
    background: linear-gradient(to right, #f3f3f3 8%, #e5e5e5 18%, #f3f3f3 33%);
    background-size: 800px 104px;
}

@keyframes shimmer {
    0% {
        background-position: -468px 0;
    }

    100% {
        background-position: 468px 0;
    }
}
</style>