<template>
    <div id="new-order">

        <b-modal hide-footer ref="substitution-items-model" title="Articles de substitution" scrollable size="lg">
            <b-overlay :show="showLoadingModal" spinner-variant="primary">
                <ag-grid :showHeader="false" :agGrid="agGridSubstitution" ref="agGridSubstitution" />
            </b-overlay>
        </b-modal>

        <b-modal centered size="sm" ref="basket-modal" hide-footer>
            <b-form-input placeholder="Nom du panier" ref="basket-name-input" v-model="basketName" type="text"
                @keyup.enter="handleOk"></b-form-input>
            <div class="d-block text-center">
                <b-button variant="relief-success" class="mt-1" @click="handleOk">
                    <feather-icon icon="ShoppingBagIcon" class="mr-10" /> Créer
                </b-button>
            </div>
        </b-modal>

        <b-modal hide-footer ref="item-modal" title="Détails de l'article" size="xl" scrollable>

            <ProductDetailsPARA v-if="selected.type == 'PARA'" :product="selected"
                @itemAddedToCart="loadOrderFromIndexDB" />
            <ProductDetailsMED v-else :product="selected" @sibstitutionItemClick="productClicked"
                @itemAddedToCart="loadOrderFromIndexDB" @sibstitutionItemClickDetail="sibstitutionItemClickDetail" />

        </b-modal>

        <b-modal hide-footer ref="arrival-modal" title="Liste des arrivages" size="xl" scrollable>

            <b-overlay :show="showLoadingModal" spinner-variant="primary">
                <Arrival @itemClicked="ItemClicked" @addToCart="addToCart" />
            </b-overlay>

        </b-modal>

        <b-modal hide-footer ref="novelty-modal" title="Liste des nouveautés" size="xl" scrollable>

            <b-overlay :show="showLoadingModal" spinner-variant="primary">
                <Novelty @itemClicked="ItemClicked" @addToCart="addToCart" />
            </b-overlay>

        </b-modal>

        <b-modal hide-footer ref="promotion-modal" title="Liste des promotions" size="xl" scrollable>

            <b-overlay :show="showLoadingModal" spinner-variant="primary">
                <Promotion @itemClicked="ItemClicked" @addToCart="addToCart" />
            </b-overlay>

        </b-modal>

        <b-modal hide-footer ref="distribution-modal" title="Articles en répartition" size="xl" scrollable>

            <b-overlay :show="showLoadingModal" spinner-variant="primary">
                <Distribution @itemClicked="ItemClicked" @addToCart="addToCart" />
            </b-overlay>

        </b-modal>

        <b-modal hide-footer ref="confirmation-model" size="xl" title="Validation commande" scrollable>
            <b-table fixed responsive :bordered="true" :small="true" :no-border-collapse="true"
                :items="agGrid.rows.filter(row => row.code !== '')" :fields="fields">
                <template #table-colgroup="scope">
                    <col v-for="field in scope.fields" :key="field.key"
                        :style="{ width: field.key === 'description' ? '200px' : '100px' }">
                </template>
                <template #cell(Total)="data">
                    {{ formatPrice(data.item.price_ttc * data.item.Qte) }}
                </template>


                <template slot="bottom-row">
                    <td><b>TOTAL</b></td>
                    <td style=" border-right: none;"></td>
                    <td style=" border-left: none;"></td>
                    <td><b>{{ formatPrice(totalExpenses) }}</b></td>
                </template>

            </b-table>
            <b-row class="text-right">
                <b-col md="12" lg="3" class="text-left">
                    <b-form-checkbox v-model="isUrgent" class="custom-control-primary">
                        Commande Urgente
                    </b-form-checkbox>
                </b-col>
                <b-col md="12" lg="6" class="text-left" style="margin-bottom: 5px;">
                    <b-form-input v-model="observation" placeholder="Observation" type="text" />
                </b-col>
                <b-col md="12" lg="3">
                    <b-button @click="saveOrder" variant="relief-success" block>
                        Valider La Commande
                    </b-button>
                </b-col>
            </b-row>
        </b-modal>

        <b-modal hide-footer ref="items-modal" title="Articles" size="xl" scrollable>

            <b-overlay :show="showLoadingModal" spinner-variant="primary">
                <ProductsList ref="productsList" @addToCart="addToCart" />
            </b-overlay>

        </b-modal>


        <b-overlay :show="showLoading" spinner-variant="primary">


            <b-row class="equal-height-cols" v-if="isNotMobileDevice">

                <b-col cols="12" md="9">
                    <b-card no-body class="border-primary flex-column">
                        <b-card-body class="flex-grow-1">
                            <b-row>
                                <b-col cols="6" sm="3">
                                    <b-button block variant="relief-primary"
                                        @click="$refs['distribution-modal'].show();">
                                        <feather-icon icon="GridIcon" /> Répartition
                                    </b-button>
                                </b-col>
                                <b-col cols="6" sm="3">
                                    <b-button block variant="relief-warning" @click="$refs['arrival-modal'].show();">
                                        <feather-icon icon="TruckIcon" class="mr-25" /> Arrivage
                                    </b-button>
                                </b-col>
                                <b-col cols="6" sm="3">
                                    <b-button block variant="relief-info" @click="$refs['novelty-modal'].show();">
                                        <feather-icon icon="FilePlusIcon" class="mr-25" /> Nouveauté
                                    </b-button>
                                </b-col>
                                <b-col cols="6" sm="3">
                                    <b-button block variant="relief-success" @click="$refs['promotion-modal'].show();">
                                        <feather-icon icon="GiftIcon" class="mr-25" /> Promotion
                                    </b-button>
                                </b-col>
                            </b-row>

                            <hr>

                            <!-- Product select and articles button: Adjusts on smaller screens -->
                            <b-row>
                                <b-col cols="12" sm="9">
                                    <products-select block :searchType="selectedType" @productClicked="productClicked"
                                        ref="productsSelect" id="productsSelect" />
                                </b-col>
                                <b-col cols="12" sm="3">
                                    <b-button block variant="relief-secondary" @click="$refs['items-modal'].show();">
                                        <feather-icon icon="SearchIcon" class="mr-25" /> Articles
                                    </b-button>
                                </b-col>
                            </b-row>
                        </b-card-body>
                    </b-card>
                </b-col>


                <b-col cols="12" md="3">
                    <b-card no-body class="border-primary flex-column">
                        <b-card-header class="d-flex justify-content-between align-items-center pt-75 pb-25">
                            <h5 class="mb-0">Totale</h5>
                            <b-badge variant="light-primary">{{ formatPrice(totalExpenses) }} TND</b-badge>
                        </b-card-header>
                        <b-card-body class="flex-grow-1">
                            <hr>
                            <b-button size="sm" variant="success" block :disabled="disableOrderBtn" @click="openModal">
                                <feather-icon icon="PocketIcon" class="mr-1" /> Commander
                            </b-button>
                            <b-button size="sm" variant="primary" block @click="addToBasket">
                                <feather-icon icon="ShoppingBagIcon" class="mr-1" /> Sauvegarder
                            </b-button>
                        </b-card-body>
                    </b-card>
                </b-col>

            </b-row>

            <b-row class="equal-height-cols" v-else>

                <b-col cols="12" md="3">
                    <products-select block :searchType="selectedType" @productClicked="productClicked"
                        ref="productsSelect" id="productsSelect" />
                </b-col>

                <b-col cols="12" md="3">
                    <b-card no-body class="border-primary flex-column">
                        <b-card-body class="flex-grow-1 mt-1">
                            <hr>
                            <b-dropdown text="Articles" block right class="m-1">
                                <b-dropdown-item @click="$refs['distribution-modal'].show();" variant="primary">
                                    <feather-icon icon="GridIcon" /> Répartition
                                </b-dropdown-item>
                                <b-dropdown-divider />
                                <b-dropdown-item @click="$refs['arrival-modal'].show();" variant="warning">
                                    <feather-icon icon="TruckIcon" /> Arrivage
                                </b-dropdown-item>
                                <b-dropdown-divider />
                                <b-dropdown-item @click="$refs['novelty-modal'].show();" variant="info">
                                    <feather-icon icon="FilePlusIcon" /> Nouveauté
                                </b-dropdown-item>
                                <b-dropdown-divider />
                                <b-dropdown-item @click="$refs['promotion-modal'].show();" variant="success">
                                    <feather-icon icon="GiftIcon" /> Promotion
                                </b-dropdown-item>
                                <!-- <b-dropdown-divider />
                        <b-dropdown-item @click="$refs['items-modal'].show();" variant="secondary">
                            <feather-icon icon="SearchIcon" /> Tous
                        </b-dropdown-item> -->
                            </b-dropdown>

                            <b-button variant="success" block :disabled="disableOrderBtn" @click="openModal">
                                <feather-icon icon="PocketIcon" class="mr-1" /> Commander
                            </b-button>
                            <!-- <b-button size="sm" variant="primary" block @click="addToBasket">
                                <feather-icon icon="ShoppingBagIcon" class="mr-1" /> Sauvegarder
                            </b-button> -->
                        </b-card-body>

                        <div class="d-flex justify-content-between align-items-center pt-75 pb-25">
                            <h5 class="mb-0">Totale</h5>
                            <b-badge variant="light-primary">{{ formatPrice(totalExpenses) }} TND</b-badge>
                        </div>
                    </b-card>
                </b-col>
            </b-row>

            <ag-grid :showHeader="false" :agGrid="agGrid" ref="agGrid" id="agGrid"
                @onCellValueChanged="onCellValueChanged" @onCellClicked="onCellClicked" />


        </b-overlay>

    </div>
</template>

<script>

import AgGrid from "@/views/components/ag-grid/AgGrid.vue"
import ProductsSelect from './ProductsSelect.vue';
import NumericEditor from './NumericEditor.js'
import IconCellRenderer from "@/views/components/ag-grid/IconCellRenderer.vue";
import ButtonCellRenderer from "@/views/components/ag-grid/ButtonCellRenderer.vue"
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import BadgeCellRenderer from "@/views/components/ag-grid/BadgeCellRenderer.vue";
import BLinkCellRenderer from "@/views/product/BLinkCellRendererWithImage.vue";
import BLinkCellRendererWithSub from "@/views/product/BLinkCellRendererWithSubs.vue";
import AddToCartCellRenderer from "./AddToCartCellRenderer.vue";
import { formatPrice } from "@/utils/formatting";
import '@core/scss/vue/libs/vue-select.scss';
import ProductDetailsMED from "@/views/product/ProductDetailsMED.vue";
import ProductDetailsPARA from "@/views/product/ProductDetailsPARA.vue";
import Arrival from "./Arrival.vue";
import Novelty from "./Novelty.vue";
import Promotion from "./Promotion.vue";
import Distribution from "./Distribution.vue";
import ProductsList from "./ProductsList.vue"

export default {
    components: {
        AgGrid,
        ProductsSelect,
        ProductsList,
        ProductDetailsMED,
        ProductDetailsPARA,
        Arrival,
        Novelty,
        Promotion,
        Distribution
    },
    data() {
        return {
            agGridSubstitution: {
                columnDefs: [
                    {
                        headerName: "Description",
                        field: "Description",
                        cellRendererFramework: 'bLinkCellRenderer',
                        cellRendererParams: { onButtonClick: this.ItemClicked, }
                    },
                    {
                        sortable: false,
                        resizable: false,
                        filter: false,
                        cellClass: 'centered-customeCellRender',
                        cellRendererFramework: 'addToCartCellRenderer',
                        cellRendererParams: { onButtonClick: this.addToCart },
                        headerName: '',
                        field: "addToCart",
                        width: 50,
                    },
                ],
                rows: []
            },
            isUrgent: false,
            basketName: "",
            showLoadingModal: false,
            selected: {},
            selectedType: "TOUS",
            totalExpenses: 0,
            disableOrderBtn: true,
            formatPrice: formatPrice,
            observation: "",
            fields: [{
                key: 'description',
                label: 'Article',
                sortable: true
            },
            {
                key: 'price_ttc',
                label: 'Prix',
                sortable: true
            },
            {
                key: 'Qte',
                label: 'Qte',
                sortable: true
            },
            {
                key: 'Total',
                label: 'Total',
                sortable: true
            },
            ],
            showLoading: true,
            agGrid: {
                singleClickEdit: true,
                columnDefs: [
                    {
                        headerName: "",
                        field: "state",
                        cellClass: 'centered-customeCellRender',
                        cellRendererFramework: 'iconCellRenderer',
                        cellRendererParams: { componentName: "NewOrder" },
                        // hide : this.MobileDevice(),
                        cellClassRules: {
                            'noborder-customeCellRender': function (params) {
                                return params.data.code === ''; // Apply class for the first row (index 0)
                            }
                        },
                        suppressMovable: true,
                    },
                    {
                        comparator: this.customComparator,
                        headerName: "Description",
                        field: "description",
                        cellRendererFramework: 'bLinkCellRendererWithSub',
                        cellRendererParams: { onButtonClick: this.showDetails, onButtonSubClick: this.onButtonSubClick },
                        cellClassRules: {
                            'noborder-customeCellRender': function (params) {
                                return params.data.code === ''; // Apply class for the first row (index 0)
                            }
                        },
                        suppressMovable: true,
                    },
                    {
                        headerName: "Quantité",
                        field: "Qte",
                        editable: params => params.data.code != "",
                        cellEditor: NumericEditor,
                        comparator: this.customComparator,
                        cellClassRules: {
                            'noborder-customeCellRender': function (params) {
                                return params.data.code === ''; // Apply class for the first row (index 0)
                            }
                        },
                        suppressMovable: true,
                    },
                    {
                        headerName: "Gratuité",
                        field: "promotion",
                        comparator: this.customComparator,
                        cellRendererFramework: 'badgeCellRenderer',
                        cellRendererParams: { componentName: "NewOrder", columnName: "promotion" },
                        cellClassRules: {
                            'noborder-customeCellRender': function (params) {
                                return params.data.code === ''; // Apply class for the first row (index 0)
                            }
                        },
                        suppressMovable: true,
                    },
                    {
                        headerName: "Prix H.T",
                        field: "price",
                        valueGetter: function (tr) {
                            if (tr.data.code != "")
                                return (tr.data.price == "" || tr.data.price == 0 || tr.data.price == undefined) ? "__" : formatPrice(tr.data.price);
                        },
                        comparator: this.customComparator,
                        cellClassRules: {
                            'noborder-customeCellRender': function (params) {
                                return params.data.code === ''; // Apply class for the first row (index 0)
                            }
                        },
                        suppressMovable: true,
                    },
                    {
                        headerName: "Prix T.T.C",
                        field: "price_ttc",
                        valueGetter: function (tr) {
                            if (tr.data.code != "")
                                return (tr.data.price_ttc == "" || tr.data.price_ttc == 0 || tr.data.price_ttc == undefined) ? "__" : formatPrice(tr.data.price_ttc);
                        },
                        cellClassRules: {
                            'noborder-customeCellRender': function (params) {
                                return params.data.code === ''; // Apply class for the first row (index 0)
                            }
                        },
                        suppressMovable: true,
                        comparator: this.customComparator,
                    },
                    {
                        headerName: "Prix public",
                        field: "public_price",
                        valueGetter: function (tr) {
                            if (tr.data.code != "")
                                return (tr.data.public_price == "" || tr.data.public_price == 0 || tr.data.public_price == undefined) ? "__" : formatPrice(tr.data.public_price);
                        },
                        comparator: this.customComparator,
                        cellClassRules: {
                            'noborder-customeCellRender': function (params) {
                                return params.data.code === ''; // Apply class for the first row (index 0)
                            }
                        },
                        suppressMovable: true,
                    },
                    {
                        headerName: "DLC",
                        field: "dlc",
                        valueGetter: function (tr) {
                            if (tr.data.code != "")
                                return (tr.data.dlc == "" || tr.data.dlc == "0001-01-01" || tr.data.dlc == undefined) ? "__" : tr.data.dlc;
                        },
                        comparator: this.customComparator,
                        cellClassRules: {
                            'noborder-customeCellRender': function (params) {
                                return params.data.code === ''; // Apply class for the first row (index 0)
                            }
                        },
                        suppressMovable: true,
                    },
                    {
                        headerName: "Cartonnage",
                        field: "packaging",
                        valueGetter: function (tr) {
                            if (tr.data.code != "")
                                return (tr.data.packaging == "" || tr.data.packaging == 0 || tr.data.packaging == undefined) ? "__" : tr.data.packaging;
                        },
                        comparator: this.customComparator,
                        cellClassRules: {
                            'noborder-customeCellRender': function (params) {
                                return params.data.code === ''; // Apply class for the first row (index 0)
                            }
                        },
                        suppressMovable: true,
                    },
                    {
                        headerName: "Totale",
                        field: "total",
                        cellClassRules: {
                            'toatl-customeCellRender': function (params) {
                                return params.data.code === ''; // Apply class for the first row (index 0)
                            }
                        },
                        valueGetter: function (tr) {
                            if (tr.data.code != "")
                                return formatPrice(tr.data.price_ttc * tr.data.Qte);
                            else {
                                // Calcul du total pour la ligne de pied de page
                                let total = 0;
                                tr.api.forEachNode(function (node) {
                                    if (node.data.code !== '') {
                                        total += node.data.Qte * node.data.price_ttc;
                                    }
                                });
                                return formatPrice(total);
                            }
                        },
                        comparator: this.customComparator,
                        suppressMovable: true,
                    },
                    {
                        field: "_",
                        sortable: false,
                        resizable: false,
                        filter: false,
                        cellClass: 'centered-customeCellRender',
                        cellRendererFramework: 'buttonCellRenderer',
                        cellRendererParams: { componentName: 'NewOrder', onButtonClick: this.deleteProduct, icon: "backspace", variant: "outline-danger" },
                        headerName: '#',
                        cellClassRules: {
                            'noborder-customeCellRender': function (params) {
                                return params.data.code === ''; // Apply class for the first row (index 0)
                            }
                        },
                        suppressMovable: true,
                    }
                ],
                rows: []
            },
        }
    },
    computed: {
        isNotMobileDevice() {
            return !/Mobi/i.test(navigator.userAgent)
        },
    },
    beforeDestroy() {
        const gridApi = this.$refs.agGrid.gridApi;
        gridApi.removeEventListener("cellKeyPress", this.cellKeyPress);
    },
    async created() {
        if (! /Mobi/i.test(navigator.userAgent))
            this.$nextTick(() => {
                this.updateColumnsWidth();
            });
        else {
            const columnIndexToUpdate = this.agGrid.columnDefs.findIndex(column => column.headerName === '#');
            this.agGrid.columnDefs[columnIndexToUpdate].width = 70;
            const columnIndexToUpdate1 = this.agGrid.columnDefs.findIndex(column => column.headerName === '');
            this.agGrid.columnDefs[columnIndexToUpdate1].width = 50;
            const columnIndexToUpdate3 = this.agGrid.columnDefs.findIndex(column => column.headerName === 'Description');
            this.agGrid.columnDefs[columnIndexToUpdate3].width = 300;
        }
        this.$options.components.addToCartCellRenderer = AddToCartCellRenderer;
        this.$options.components.iconCellRenderer = IconCellRenderer;
        this.$options.components.buttonCellRenderer = ButtonCellRenderer;
        this.$options.components.badgeCellRenderer = BadgeCellRenderer;
        this.$options.components.bLinkCellRenderer = BLinkCellRenderer;
        this.$options.components.bLinkCellRendererWithSub = BLinkCellRendererWithSub;
        this.loadData();
    },
    methods: {
        onCellValueChanged() {
            let total = 0;
            this.agGrid.rows.forEach(element => {
                if (element.code !== '') {
                    total += element.Qte * element.price_ttc;
                }
            });
            this.totalExpenses = total;
        },
        onCellClicked(params) {
            if (params.column.colId == "Qte") {
                const rowIndex = params.rowIndex;
                const gridApi = this.$refs.agGrid.gridApi;
                gridApi.startEditingCell({ rowIndex, colKey: params.column.colId });
                // this.disableOrderBtn = true;
            }
        },
        async onButtonSubClick(data) {
            this.showLoading = true;
            if (data.type == 'MED') {
                setTimeout(() => {
                    this.agGridSubstitution.rows = this.$store.getters["app-items/geItemsMED"].filter(item => item.dciDescription === data.dciDescription);
                    const index = this.agGridSubstitution.rows.findIndex(product => product.code === data.code);
                    if (index !== -1)
                        this.agGridSubstitution.rows.splice(index, 1);
                }, 500);
            } else {
                setTimeout(() => {
                    this.agGridSubstitution.rows = this.$store.getters["app-items/geItemsPARA"].filter(item => item.familyDescription === data.familyDescription && item.subFamilyDescription === data.subFamilyDescription);
                    const index = this.agGridSubstitution.rows.findIndex(product => product.code === data.code);
                    if (index !== -1)
                        this.agGridSubstitution.rows.splice(index, 1);
                }, 500);
            }
            this.showLoading = false;
            this.$refs['substitution-items-model'].show();
            // let resp = await this.$http.get("_item/getItemNoByItemCode/" + data.code).catch(() => this.showLoading = false);
            // if (resp.data != "") {
            //     let respItem = await this.$http.get('substitution_item/findWhere', { params: { No: "'" + resp.data + "'" } }).catch(() => this.showLoading = false);
            //     setTimeout(() => {
            //         this.agGridSubstitution.rows = respItem.data;
            //     }, 300);
            //     this.showLoading = false;
            //     this.$refs['substitution-items-model'].show();
            // }
        },
        async addToCart(item) {
            this.showLoadingModal = true;
            let code = item.code;

            if (item.code == undefined) {
                let co = item.Substitute_No == undefined ? item.No : item.Substitute_No;
                let resp = await this.$http.get("_item/getItemCodeByItemNo/" + co);
                if (resp.data != "") {
                    code = resp.data;
                } else {
                    this.showLoadingModal = false;
                    this.$swal({
                        title: "Article Actuellement Indisponible",
                        text: "Désolé, cet article n'a pas encore été créé chez Pharmaconnect.",
                        icon: 'warning',
                        customClass: {
                            confirmButton: 'btn btn-primary',
                        },
                        buttonsStyling: false,
                    });
                    this.showLoadingModal = false;
                    return;
                }
            }

            this.showLoadingModal = false;
            let element = this.$store.getters["app-items/geItemByCode"](code);
            setTimeout(() => {
                this.productClicked(element);
            }, 300);

        },
        customComparator(valueA, valueB, nodeA) {
            if (nodeA && nodeA.data && nodeA.data.code === '') {
                return 0;
            } else {
                return valueA.localeCompare(valueB);
            }
        },
        async handleOk() {
            this.$refs["basket-modal"].hide();
            this.showLoading = true;
            let headerId = await this.$store.dispatch('app-baskets/ADD_BASKET_HEADER', this.basketName.trim() == "" ? undefined : this.basketName);
            this.agGrid.rows.forEach(async element => {
                if (element.code != '') {
                    this.$store.dispatch('app-items/DELETE_ORDER_LINE', element.code);
                    element.basketHeaderId = headerId;
                    await this.$store.dispatch('app-baskets/ADD_BASKET_LINE', element);
                }
            });
            this.agGrid.rows = [];
            this.$swal({
                icon: 'success',
                title: "La commande a été enregistrée avec succès dans le panier.",
                timer: 10000,
                customClass: {
                    confirmButton: 'btn btn-primary',
                },
                buttonsStyling: false,
            });
            this.showLoading = false;
        },
        async addToBasket() {
            if (this.agGrid.rows.length > 1) {
                this.basketName = "";
                this.$refs["basket-modal"].show();
                setTimeout(() => {
                    this.$refs["basket-name-input"].focus();
                }, 400);
            }
        },
        sibstitutionItemClickDetail(data) {
            this.$refs['item-modal'].hide();
            this.selected = data;
            setTimeout(() => {
                this.$refs['item-modal'].show();
            }, 300);
        },
        async ItemClicked(data) {
            this.showLoadingModal = true;
            if (data.code == undefined) {
                console.log("1")
                let resp = await this.$http.get("_item/getItemCodeByItemNo/" + data.No);
                if (resp.data != "") {
                    this.selected = this.$store.getters["app-items/geItemByCode"](resp.data);
                    this.$refs['item-modal'].show();
                } else {
                    this.$swal({
                        title: "Article Actuellement Indisponible",
                        text: "Désolé, cet article n'a pas encore été créé chez Pharmaconnect.",
                        icon: 'warning',
                        customClass: {
                            confirmButton: 'btn btn-primary',
                        },
                        buttonsStyling: false,
                    })
                }
            } else {
                console.log("2")
                this.selected = this.$store.getters["app-items/geItemByCode"](data.code);
                this.$refs['item-modal'].show();
            }
            this.showLoadingModal = false;
        },
        updateColumnsWidth() {
            const viewportWidth = this.$refs.agGrid.$el.offsetWidth; // Get the viewport width
            const widths = {
                '_': 5,
                total: 8,
                packaging: 7,
                dlc: 8,
                public_price: 8,
                price_ttc: 8,
                price: 8,
                promotion: 8,
                Qte: 11,
                description: 25,
                state: 4,
            };
            // Calculate pixel widths based on percentages
            const newColumnDefs = this.$refs.agGrid.gridOptions.columnDefs.map(colDef => {
                const widthPercentage = widths[colDef.field];
                if (widthPercentage) {
                    const widthPx = Math.floor((viewportWidth * widthPercentage) / 100);
                    return { ...colDef, width: widthPx };
                }
                return colDef; // Return original definition if no percentage is defined
            });
            this.$refs.agGrid.gridOptions.api.setColumnDefs(newColumnDefs);

        },
        async showDetails(data) {
            let element = this.$store.getters["app-items/geItemByCode"](data.code);
            this.selected = element;
            this.$refs['item-modal'].show();
        },
        openModal() {
            this.observation = "";
            this.$refs['confirmation-model'].show();
        },
        saveOrder() {
            this.$refs['confirmation-model'].hide();
            this.showLoading = true;
            let orderDto = {
                lines: [],
                observation: this.observation,
                isUrgent: this.isUrgent
            };
            let lines = this.agGrid.rows.map(function (obj) {
                return {
                    no: obj.code,
                    qte: parseInt(obj.Qte)
                };
            });
            orderDto.lines = lines;
            this.$http.post("_order", orderDto).then((response) => {
                this.showLoading = false;
                if (response.data.status == 200) {
                    this.$toast.clear();
                    this.agGrid.rows.forEach(async element => {
                        if (element.code != '') {
                            this.$store.dispatch('app-items/DELETE_ORDER_LINE', element.code);
                        }
                    });
                    this.agGrid.rows = [];
                    this.isUrgent = false;
                    this.confirmSwall = this.$swal({
                        icon: 'success',
                        title: "Votre commande a été soumise avec succès. Nous la traiterons rapidement.",
                        timer: 10000,
                        customClass: {
                            confirmButton: 'btn btn-primary',
                        },
                        buttonsStyling: false,
                    });
                } else {
                    if (response.data.status == 405) {
                        this.agGrid.rows.find(item => item.code === response.data.result).state = false;
                        let row = this.agGrid.rows.find(item => item.code === response.data.result);
                        row.state = false;
                        this.$toast.clear();
                        this.confirmSwall = this.$swal({
                            icon: 'error',
                            text: "Nous regrettons de vous informer que l'article " + row.description + " n'est pas vendable chez le grossiste " + response.data.error + " pour le moment.",
                            timer: 15000,
                            customClass: {
                                confirmButton: 'btn btn-primary',
                            },
                            buttonsStyling: false,
                        });
                    } else if (response.data.status == 406) {
                        this.agGrid.rows.find(item => item.code === response.data.result).state = false;
                        let row = this.agGrid.rows.find(item => item.code === response.data.result);
                        row.state = false;
                        this.$toast.clear();
                        this.confirmSwall = this.$swal({
                            icon: 'error',
                            text: "Oups ! La quantité demandée de l'article " + row.description + " n'est plus disponible pour le moment.",
                            timer: 15000,
                            customClass: {
                                confirmButton: 'btn btn-primary',
                            },
                            buttonsStyling: false,
                        });
                    } else {
                        this.showLoading = false;
                        this.$swal({
                            title: "Erreur s'est produite lors de la récupération des données",
                            text: response.data.error,
                            icon: 'error',
                            customClass: {
                                confirmButton: 'btn btn-primary',
                            },
                            buttonsStyling: false,
                        });
                    }
                }


                this.disableBtn();
            }).catch((error) => {
                console.log(error);
                this.disableBtn();
                this.showLoading = false;
                this.$swal({
                    title: "Erreur s'est produite lors de la récupération des données",
                    text: error.response.data,
                    icon: 'error',
                    customClass: {
                        confirmButton: 'btn btn-primary',
                    },
                    buttonsStyling: false,
                });
            });
        },
        deleteProduct(data) {
            const index = this.agGrid.rows.findIndex(product => product === data);
            if (index !== -1) {
                // if (this.agGrid.rows[index].state)
                this.$store.dispatch('app-items/DELETE_ORDER_LINE', this.agGrid.rows[index].code);
                this.agGrid.rows.splice(index, 1);
                this.$refs.agGrid.gridOptions.api.setRowData(this.agGrid.rows);
                // if (this.agGrid.rows.length == 1)
                //     this.agGrid.rows = [];
                this.disableBtn();
                let total = 0;
                this.agGrid.rows.forEach(element => {
                    if (element.code !== '') {
                        total += element.Qte * element.price_ttc;
                    }
                });
                this.totalExpenses = total;
            }
        },
        cellKeyPress(event) {
            if (event.event.key === "Enter") {
                const focusedCell = this.$refs.agGrid.gridOptions.api.getFocusedCell();
                if (focusedCell && focusedCell.column.colId === "Qte") {
                    if (event.data.Qte.trim() === '' || parseInt(event.data.Qte) === 0) {
                        event.data["state"] = false;
                        this.$nextTick(() => {
                            this.$refs.productsSelect.$refs["select"].$el.querySelector("input").focus();
                            this.disableBtn();
                        });
                        return;
                    }
                    this.showLoading = true;
                    this.$store.dispatch('app-items/GET_INVENTORY', event.data).then((response) => {
                        // console.log(response);
                        this.showLoading = false;
                        this.$toast.clear();
                        this.$toast({
                            component: ToastificationContent,
                            props: response,
                        },
                            {
                                timeout: 2000
                            });
                        event.data.promotion = response.promotion;
                        event.data.price = response.price;
                        event.data.price_ttc = response.price_ttc;
                        event.data.public_price = response.public_price;
                        event.data.packaging = response.packaging;
                        event.data.dlc = response.dlc;
                        event.data["state"] = !["warning", "danger"].includes(response.variant);
                        var rowNodee = this.$refs.agGrid.gridOptions.api.getDisplayedRowAtIndex(event.rowIndex);
                        // this.$refs.agGrid.gridOptions.api.flashCells({
                        //     rowNodes: [rowNodee],
                        //     columns: ['Qte'],
                        // });
                        this.$refs.agGrid.gridOptions.api.flashCells({
                            rowNodes: [rowNodee],
                            columns: ['price'],
                        });
                        this.$refs.agGrid.gridOptions.api.flashCells({
                            rowNodes: [rowNodee],
                            columns: ['price_ttc'],
                        });
                        this.$refs.agGrid.gridOptions.api.flashCells({
                            rowNodes: [rowNodee],
                            columns: ['public_price'],
                        });
                        this.$refs.agGrid.gridOptions.api.flashCells({
                            rowNodes: [rowNodee],
                            columns: ['promotion'],
                        });
                        this.$refs.agGrid.gridOptions.api.flashCells({
                            rowNodes: [rowNodee],
                            columns: ['packaging'],
                        });
                        this.$refs.agGrid.gridOptions.api.flashCells({
                            rowNodes: [rowNodee],
                            columns: ['dlc'],
                        });
                        this.$refs.agGrid.gridOptions.api.refreshCells()
                        this.$nextTick(() => {
                            this.$refs.productsSelect.$refs["select"].$el.querySelector("input").focus();
                            this.disableBtn();
                            let total = 0;
                            this.agGrid.rows.forEach(element => {
                                if (element.code !== '') {
                                    total += element.Qte * element.price_ttc;
                                }
                            });
                            this.totalExpenses = total;
                        });
                    }).catch((error) => {
                        this.$toast.clear();
                        if (error.title == "Article Actuellement Indisponible") {
                            this.$toast({
                                component: ToastificationContent,
                                props: error,
                            },
                                {
                                    position: 'top-left',
                                },
                                {
                                    timeout: 4000
                                }
                            );
                        } else {
                            this.$toast({
                                component: ToastificationContent,
                                props: error,
                            },
                                {
                                    timeout: 4000
                                });
                        }
                        this.showLoading = false;
                    });
                }
            }
        },
        productClicked(data) {
            this.$refs['item-modal'].hide();
            this.$refs['items-modal'].hide();
            this.$refs["arrival-modal"].hide();
            this.$refs["novelty-modal"].hide();
            this.$refs["promotion-modal"].hide();
            this.$refs["distribution-modal"].hide();
            this.$refs["substitution-items-model"].hide();
            this.$refs.agGrid.gridOptions.api.setSortModel([]);
            this.$refs.agGrid.gridOptions.api.refreshClientSideRowModel();
            const existingIndex = this.agGrid.rows.findIndex(row => row.code === data.code);
            if (existingIndex !== -1) {
                const existingProduct = this.agGrid.rows.splice(existingIndex, 1)[0];
                let item = this.agGrid.rows[0];
                if (item != undefined && !item.state && item.code)
                    this.agGrid.rows.splice(0, 1);
                existingProduct.Qte = "";
                existingProduct.state = false;
                this.agGrid.rows.unshift(existingProduct);
            } else {
                // if (this.agGrid.rows.length == 0) {
                //     this.agGrid.rows.push({ code: "", total: "" });
                // }
                let item = this.agGrid.rows[0];
                if (item != undefined && !item.state && item.code)
                    this.agGrid.rows.splice(0, 1);
                data.state = false;
                data.Qte = "";
                data.price = data.price == undefined ? "" : data.price;
                data.price_ttc = data.price_ttc == undefined ? "" : data.price_ttc;
                data.public_price = data.public_price == undefined ? "" : data.public_price;
                this.agGrid.rows.unshift(data);
            }
            setTimeout(() => {
                const rowIndex = 0;
                const colId = "Qte";
                const gridApi = this.$refs.agGrid.gridApi;
                gridApi.startEditingCell({ rowIndex, colKey: colId });
                this.disableOrderBtn = true;
            }, 300);
        },
        async loadOrderFromIndexDB() {
            this.showLoading = true;
            let order = await this.$store.dispatch('app-items/FETCH_ORDERS');
            order = order.reverse();
            // if (order.length != 0) {
            //     order.push({ code: "", total: "" });
            // }
            this.agGrid.rows = order;
            this.showLoading = false;
            let total = 0;
            this.agGrid.rows.forEach(element => {
                if (element.code !== '') {
                    total += element.Qte * element.price_ttc;
                }
            });
            this.totalExpenses = total;
        },
        disableBtn() {
            this.disableOrderBtn = this.agGrid.rows.length === 0 ? true : this.agGrid.rows.some((item) => {
                return !item.state && item.code !== "";
            });
        },
        async loadData() {
            await this.loadOrderFromIndexDB();
            this.$nextTick(() => {
                const gridApi = this.$refs.agGrid.gridApi;
                gridApi.addEventListener("cellKeyPress", this.cellKeyPress);
                this.disableBtn();
            });
        },
    }
}
</script>
<style lang="scss" scoped>
.equal-height-cols {
    display: flex;
}

.flex-column {
    display: flex;
    flex-direction: column;
    height: 100%;
    margin-bottom: 0px !important;
    padding-bottom: 0px !important;
}

.flex-grow-1 {
    flex-grow: 1;
}

@media (max-width: 768px) {
    .card {
        background-color: initial;
        box-shadow: initial;
    }

    .card-body {
        padding: 0px;
    }

    .border-primary {
        border: none !important;
        /* Use important to override any specific inline styles if necessary */
    }
}
</style>